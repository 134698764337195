import React from 'react';
import * as styles from './link-button.module.scss';
import { classNames } from '../../../utils';
import { Link } from 'gatsby';


export default function LinkButton( { to, href, isSecondary, newTab, style, children } ) {
  const className = classNames(
    styles.linkButton,
    styles[ `linkButton${ isSecondary ? 'Secondary' : 'Primary' }` ]
  );

  if ( to ) {
    return (
      <Link to={ to } className={ className }>
        <span>{ children }</span>
      </Link>
    );
  }

  if ( href ) {
    const target = newTab ? { rel: 'noopener noreferrer', target: '_blank' } : {};

    return (
      <a href={ href } className={ className } style={ style } { ...target }>
        <span>{ children }</span>
      </a>
    );
  }

  return null;
}